<script setup>
import profilePicture from "@/assets/images/self.jpeg"; // Import the image
import { useTitleRotate } from '@/composables/useTitleRotate'

const titles = {
  engineer: "software engineer",
  devOps: "dev ops",
  gameDev: "game dev"
}

const description = "Software Engineer who has designed and developed scalable, modular systems across web and game development. Experienced in APIs, DevOps (Docker, CI/CD), and system design. Blends deep technical expertise with a relentless drive for efficiency—breaking down complex problems, engineering seamless solutions, and pushing the boundaries of what’s possible."

const { currentTitle } = useTitleRotate(titles, 500);
</script>

<template>
  <div data-grid-container class="

  w-full 
  bg-[#FCF3D6] 
  h-screen
  grid 
  grid-cols-12 
  grid-rows-12
  gap-4
  font-mono  
  ">
    <div data-grid-item-1 class="
    col-start-1
    col-end-13


    sm:col-start-1
    sm:col-end-10
    
    md:col-start-1
    md:col-end-9
    

    2xl:col-start-2
    2xl:col-end-9

    3xl:col-start-2
    


    row-start-1
    row-end-4

    sm:row-start-3

    md:row-start-2
    
    lg:row-start-5
 


    "
    >
      <div data-grid-item-1-container class="flex flex-col p-2 gap-3">
        <p class="text-center text-[1.4em]"><small class="text-center font-bold">boskyle ~ </small><small v-html="currentTitle.value" /></p>
        <div image-container class="w-[40%] m-auto">
        <img class="max-h-[100%] max-w-[100%] m-auto border-black border-4" :src="profilePicture" alt="Profile Picture" />
        </div>
      </div>
    </div>

    <div data-grid-item-2  class=" 
    col-start-2 
    col-end-12


    sm:col-start-6

    md:col-start-6
    md:col-end-12

    2xl:col-start-6
    2xl:col-end-11

    3xl:col-start-6
    3xl:col-end-11

    4xl:col-start-6
    4xl:col-end-10

    

    row-start-5
    row-end-10

    sm:row-start-4
    
    md:row-start-4

    lg:row-start-5

    xl:row-start-6

  
    
    ">
        <p  class="text-[#FCF3D6] 
        border-2 border-[#FCF3D6]
        bg-black 
        p-3
        leading-6

        text-[0.8em]
        md:text-[0.8em]


        " 
      
        v-html="description"/>
    </div>

    <div data-grid-item-3 class="
    col-start-2 col-end-12
    row-start-12

    
    md:row-start-11
    lg:row-start-9
    xl:row-start-11

    
    ">
      <div class="flex flex-row justify-center gap-3 text-[1.5em] md:mt-3">
        <RouterLink to="/experiences"><small class="p-2 font-bold cursor-pointer">[experience]</small></RouterLink>
        <RouterLink to="/projects"><small class="p-2 font-bold cursor-pointer">[projects]</small></RouterLink>

      </div>

      <div>

      </div>
    </div>

  </div>
</template>

<style scoped>
/* Fade-in keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply the animation */
[data-grid-item-1],
[data-grid-item-2],
[data-grid-item-3] {
  animation: fadeIn 1s ease-in-out;
}

/* Stagger the animations */
[data-grid-item-1] {
  animation-delay: 0s;
}

[data-grid-item-2] {
  animation-delay: 0.25s;
}

[data-grid-item-3] {
  animation-delay: 0.5s;
}
</style>

